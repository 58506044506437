/* HigherEd Project CSS
 * version 1.5 
 *  switched to common extends 1.1 with new font faces
 * version 1.4
 *  changed min-height
 * version 1.1
 *  Updated to work with isrdi-common.1.4
 *  Dropping bundle CSS
 */

$color_project_primary: rgb(191,13,62);  // highered red #bf0d3e
$color_project_secondary: rgb(245,219,226); // highered light #f5dbe2

@import 'common-isrdi-variables.1.0';
@import 'base-ipums-variables.1.0';
@import 'common-isrdi-extends.1.1';

$color_link: $color_project_primary;
$color_link_ha: mix($color_project_primary,$color_near_white);

// ***
// * BEGIN Visual Styles for Page Structural Elements
// ***
nav[id="user_bar"] {
  color: $color_project_secondary;
}
nav[id="topNav"] {
// navigation appearing at top right of page
  border-top-color:$color_project_secondary;

  .list-menu li a:hover {
    color:$color_link;
  }
}
header {
  background-image: url("//assets.ipums.org/_img/bkg-Number-Bar-Higher-Ed.png");
}
div[id="picturebar"] {
// decorative pictures in the header 
  div {
    background-color:$color_project_secondary;

    &:nth-child(1) { background-image:url("//assets.ipums.org/_img/highered-top-img-1.jpg"); }
    &:nth-child(2) { background-image:url("//assets.ipums.org/_img/highered-top-img-2.jpg"); }
    &:nth-child(3) { background-image:url("//assets.ipums.org/_img/highered-top-img-3.jpg"); }
    &:nth-child(4) { background-image:url("//assets.ipums.org/_img/highered-top-img-4.jpg"); }
    &:nth-child(5) { background-image:url("//assets.ipums.org/_img/highered-top-img-5.jpg"); }
    &:nth-child(6) { background-image:url("//assets.ipums.org/_img/highered-top-img-6.jpg"); }
  }
  &::after {
    background: linear-gradient(
      $color_primary,
      $color_primary $size_picturebar_border,
      $color_project_primary $size_picturebar_border,
      $color_project_primary 2*$size_picturebar_border);
  }
}
nav[id="sidebar-left"] {
  li {
    &.active {
      background-color:$color_project_primary;
    }
    a:hover { 
      color: $color_project_primary;
    }
    h3 {
      color:$color_project_primary;
    }
  }
}
// make sure menu doesn't get cut off
main, div[id="main"] {
  min-height:56rem;
}
footer {
  .list-menu {
    li {
      a:hover, a:active {
        color:$color_project_primary;
      }
    }
  }
  p {
    a:hover, a:active { 
      color:$color_project_primary;
    }
  }
}
// ***
// * END Visual Styles for Page Structural Elements
// ***

// ***
// * BEGIN General Tag Styling
// ***
a {
  &:link {
    color: $color_link;
  }
  &:hover {
    color: $color_link_ha;
  }
}
th {
  a {
    &:link {
      color: darken($color_link,5%);
    }
    &:hover {
      color: $color_link_ha;
    }
  }
}
table:not(.no-hover) tr:hover td {
  background-color:transparentize($color_project_primary,.85);
}
hr {
// is this ever used?
  background-color: $color_project_primary;
}
// ***
// * END General Tag Styling
// ***

// ***
// * BEGIN Specialized Classes
// ***
table.striping:not(.no-hover)  tr:nth-child(2n):hover td {
  background-color:transparentize($color_project_primary,.85);
}
.buttonbox {
  background-color:$color_project_primary;

  /* some custom stuff for one button version */
  text-align:left;
  h3 {
    float:left;
    display:inline-block;
    margin-left:$width-gutter;
    margin-right:$width-gutter;
  }
  p {
    float:left;
    @include header-bars($color_near_white, 2em, 0);
    &::before {
      width:0;
    }
    &::after {
      margin-top:0;
      top: 1.4rem;
      width: 34rem;
    }
  }
  /* override with project colors */
  a {
    &:link, &:visited {
      color: $color_project_primary;
    }
    &:hover {
      color:$color_project_primary;
      background-color:$color_project_secondary;
    }
    &:active {
      background-color:$color_near_white;
    }
  }
}

